import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AdminDialog from './AdminDialog'
import Swal from 'sweetalert2';

export default function ProductCard({ product,refreshData }) {

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = (isSubmitted) => {
        setOpenDialog(false);
        if (isSubmitted) {
          refreshData(); // Refresh data only if submission was successful
        }
      };

    const handleDelete = async () => {
      // Show SweetAlert2 confirmation dialog
      const result = await Swal.fire({
          title: `ยืนยันลบสินค้า "${product?.item_name}"`,
          text: 'หากลบสินค้านี้แล้วจะไม่สามารถกู้คืนได้!',
          icon: 'warning',
          iconColor: '#C7253E',
          showCancelButton: true,
          confirmButtonColor: '#C7253E',
          confirmButtonText: 'ใช่ ลบมัน!',
          cancelButtonText: 'ยกเลิก'
      });

      if (result.isConfirmed) {
          try {
            Swal.fire({
                title: 'กรุณารอสักครู่',
                text: 'กำลังลบข้อมูล...',
                allowOutsideClick: false, // Prevent closing the alert
                didOpen: () => {
                  Swal.showLoading();
                }
              });

              // Send DELETE request to API
              await fetch(`/api/inventory/${product.inventory_id}`, {
                  method: 'DELETE',
              });

              // Notify success and refresh or handle UI update
              Swal.fire(
                  'ลบแล้ว!',
                  'สินค้าได้ถูกลบออกจากฐานข้อมูลแล้ว.',
                  'success'
              );

              refreshData()

    
          } catch (error) {
              // Handle error
              Swal.fire(
                  'Error!',
                  'Something went wrong. Please try again later.',
                  'error'
              );
          }
      }
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
          sx={{
            height: 200,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
                    src={product?.images[0]?.image_path || 'uploads/amulet_default.png'} // ดึงภาพจาก API
                    alt={product?.item_name}
                    style={{
                        height: '100%',
                        width: 'auto',
                        objectFit: 'cover',
                    }}
                />
        </Box>
        <CardContent
                sx={{
                flexGrow: 1, // ทำให้ CardContent ขยายตัวเพื่อเติมพื้นที่ที่เหลือ
                }}
            >
      <Typography gutterBottom variant="h5" component="div">
              {product.item_name} {/* ชื่อสินค้า */}
          </Typography>
          <Typography variant="body2" >
              {product.description.length > 200 ? `${product.description.slice(0, 200)}...` : product.description} {/* คำอธิบายสินค้า */}
          </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small">Share</Button> */}
        <Button size="small" variant="contained" onClick={handleOpenDialog}>edit</Button>
        <Button size="small" color='error' variant="outlined" onClick={handleDelete} >Delete</Button>
        <AdminDialog openDialog={openDialog} onClose={handleCloseDialog} product={product}/>
      </CardActions>
    </Card>
  );
}
