import React,{useRef,useState,useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ProductCard from './ProductCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const ProductSwiper = ({ products }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const slidesPerView = isMobile ? 1 : isMedium ? 2 : 4;

  // กรองผลิตภัณฑ์ที่มี super_hit เป็น true
  const superHitProducts = products.filter(product => product.super_hit);





  return (
   
    <Swiper
      spaceBetween={5}
      slidesPerView={slidesPerView}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      autoplay={{
        delay: 2500, // Time in ms between each slide (2.5 seconds)
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
 
    >
      {superHitProducts.length > 0 ? (
        superHitProducts.map(product => (
          <SwiperSlide key={product.id}  >
            <Box
              
              sx={{padding:1,height:400}}          
              >
              <ProductCard product={product} swiper={true}  /> {/* ส่งข้อมูลสินค้าไปยัง ProductCard */}
            </Box>
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide>
          <Box sx={{ padding: 1 }}>
            <Typography>No super hit products available.</Typography>
          </Box>
        </SwiperSlide>
      )}
    </Swiper>

  );
};

export default ProductSwiper;
