import React, { useEffect, useState } from 'react';
import {  Typography, Paper, Box } from '@mui/material';
import PCardGrid from '../components/PCardGrid'

const AdminPage = () => {
  const [products, setProducts] = useState([]); // ใช้สถานะสำหรับผลิตภัณฑ์
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch('/api/inventory');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchData();
  }, []);

  const handleRefreshData = () => {
    fetchData(); // Fetch products when dialog closes successfully
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div>

      <Box>
        <Paper style={{ padding: 16 }}>
          <Typography variant="h1" gutterBottom>
          Admin Dashboard
          </Typography>
          <Typography variant="h5" gutterBottom>
            This is an example of how to use MUI components.
          </Typography>

          <PCardGrid role='admin' products={products} refreshData={handleRefreshData} />
        </Paper>
        
      </Box>
    </div>
  );
};

export default AdminPage;
