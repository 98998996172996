import * as React from 'react';
import { Grid, Container, Typography, CardMedia, Card, Box, CardContent, Button, Paper, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import AnnouncementDialog from '../components/AnnouncementDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const TruncatedTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 3,
  whiteSpace: 'normal',
  wordBreak: 'break-word', 
}));

// Styled Paper component for blog posts
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'left',
  marginBottom: theme.spacing(2),
  boxSizing: 'border-box', 
  [theme.breakpoints.up('xs')]: {
    width: '100%', // Full width on small screens
  },
  [theme.breakpoints.up('sm')]: {
    width: '600px', // Set width for small screens and up
  },
  [theme.breakpoints.up('md')]: {
    width: '800px', // Set width for medium screens and up
  },
  [theme.breakpoints.up('lg')]: {
    width: '1000px', // Set width for large screens and up
  },
}));

const NewsPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blogPosts, setBlogPosts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleClickOpen = (post) => {
    setSelectedPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPost(null);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        // Show waiting alert
        Swal.fire({
          title: 'Deleting...',
          text: 'Please wait while we delete the item.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        // Send delete request
        const response = await fetch(`/api/announcements/${id}`, {
          method: 'DELETE'
        });

        if (!response.ok) {
          throw new Error('Failed to delete');
        }

        // Remove the deleted post from the state
        setBlogPosts((posts) => posts.filter(post => post.id !== id));

        // Show success alert
        Swal.fire({
          title: 'Deleted!',
          text: 'Your item has been deleted.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } catch (error) {
        console.error('Delete failed:', error);
        Swal.fire({
          title: 'Error!',
          text: 'There was an issue deleting the item.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetch('/api/announcements');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBlogPosts(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData(); // Refresh announcements
  };

  return (
    <Container>
      <Paper style={{ padding: 16 }}>
        <Typography variant="h1" gutterBottom>
          ประชาสัมพันธ์
        </Typography>
        <Button variant='contained' onClick={handleOpenDialog}> New Announcement</Button>
        <Container maxWidth="lg" sx={{ marginTop: 4 }}>
          <Grid container spacing={2} justifyContent="center">
            {blogPosts.map((post) => (
              <Grid item xs={12} sm={6} md={12} key={post.id}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative', // To position the delete button correctly
                  }}
                >
                  <StyledPaper
                    sx={{
                      padding: 3,
                      textAlign: 'left',
                
                      marginBottom: 2,
                      maxWidth: { xs: '500px', md: '1000px' }, 
                      boxSizing: 'border-box', 
                    }}
                  >
                    <Card>
                      {/* ใช้ Box สำหรับจัดเลย์เอาต์ในแนวนอน */}
                      <Box
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        alignItems="flex-start"
                      >
                        <Box
                          sx={{
                            flex: '0 0 400px', // กำหนดขนาดคงที่ของ Box รูปภาพ
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={post?.image_path}
                            alt={post?.title}
                            sx={{
                              maxWidth: '100%',
                              height: 'auto',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            flex: '1 1 auto',
                            padding: 2,
                          }}
                        >
                          <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                              {post?.title}
                            </Typography>
                            <Typography  gutterBottom>
                              {post?.time_stamp}
                            </Typography>
                            <TruncatedTypography variant="body2" >
                              {post?.detail}
                            </TruncatedTypography>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ marginTop: 2 }}
                              onClick={() => handleClickOpen(post)}
                            >
                              Read More
                            </Button>
                          </CardContent>
                        </Box>
                      </Box>
                      {/* Delete Button */}
                      <IconButton
                        onClick={() => handleDelete(post.id)}
                        sx={{
                          position: 'absolute',
                          top: 40,
                          right: 80,
                          backgroundColor: 'error.main',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'error.dark',
                          },
                        }}
                        variant="contained"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Card>
                  </StyledPaper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Paper>

      <AnnouncementDialog open={dialogOpen} onClose={handleCloseDialog} onRefresh={handleRefresh} />

      <Dialog open={open} onClose={handleClose}>
        <Card sx={{ maxWidth: 'auto' }}>
          <CardMedia
            component="img"
            sx={{ width: '100%' }}
            image={selectedPost ? selectedPost.image_path : null}
            title="Announcement Image"
            alt={selectedPost ? selectedPost.title : 'Announcement'}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {selectedPost ? selectedPost.title : 'No Title'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
              {selectedPost ? selectedPost.detail : 'No Details'}
            </Typography>
          </CardContent>
        </Card>
      </Dialog>
    </Container>
  );
};

export default NewsPage;
