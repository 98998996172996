import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

// ตัวอย่างข้อมูลบัตรใบรับรองสินค้า
const certificates = [
  {
    id: 1,
    title: 'Certificate of Authenticity',
    image: '/images/sku01.png', // URL ของภาพ
    description: 'This certificate verifies the authenticity of the product.',
    issuedDate: '2023-09-01'
  },
  {
    id: 2,
    title: 'Quality Assurance Certificate',
    image: '/images/sku02.png', // URL ของภาพ
    description: 'This certificate confirms the quality standards met by the product.',
    issuedDate: '2023-09-15'
  },
  // เพิ่มข้อมูลใบรับรองสินค้าเพิ่มเติมที่นี่
];

const CertificateCard = ({ certificate }) => (
  <Card sx={{ display: 'flex',  margin: 2 }}>
    <CardMedia
      component="img"
      sx={{
        maxWidth: 150, // ความกว้างของรูปภาพ
        height: 'auto', // ความสูงอัตโนมัติ
        objectFit: 'cover',
      }}
      image={certificate.image}
      alt={certificate.title}
    />
    <CardContent sx={{ flex: '1 0 auto' }}>
      <Typography gutterBottom variant="h5" component="div">
        {certificate.title}
      </Typography>
      <Typography variant="body2">
        {certificate.description}
      </Typography>
      <Typography variant="body2" sx={{ marginTop: 1 }}>
        Issued Date: {certificate.issuedDate}
      </Typography>
    </CardContent>
  </Card>
);

const CertificatePage = () => (
  <Container>
    <Typography variant="h4" component="h1" gutterBottom>
      Product Certificates
    </Typography>
    <Grid container spacing={4}>
      {certificates.map(certificate => (
        <Grid item xs={12} sm={6} md={6} key={certificate.id}>
          <CertificateCard certificate={certificate} />
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default CertificatePage;
