import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProductDialog from './ProductDialog'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const getStateLabel = (stateType) => {
    switch (stateType) {
      case 'new':
        return 'มาใหม่';
      case 'sale':
        return 'เปิดให้บูชา';
      case 'sold':
        return 'ให้บูชาแล้ว';
      case 'show':
        return 'โชว์พระ';
      default:
        return 'ไม่ทราบสถานะ';
    }
  };
  
  // ฟังก์ชันกำหนดสีของ Chip ตาม state_type
  const getChipColor = (stateType, theme) => {
    switch (stateType) {
      case 'new':
        return theme.palette.newitem.main; // Use theme color
      case 'sale':
        return theme.palette.saleitem.main; // Use theme color
      case 'sold':
        return theme.palette.solditem.main; // Use theme color or customize
      case 'show':
        return theme.palette.showitem.main; // Use theme color
      default:
        return theme.palette.primary.main; // Fallback to primary color
    }
};

export default function ProductCard({ product,swiper }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    

    return (
      <>
        <Card
        onClick={handleOpenDialog} // Make the entire card clickable

        sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%', // Ensure the card expands to fill its container
            backgroundColor: theme.palette.background.card,
            cursor: 'pointer', // Indicate that the card is clickable
            '&:hover': { boxShadow: 6 }, // Optional: add a shadow on hover for better UX
  
        }}
   
        >

            <Box
                sx={{
                    height: 200,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                   src={product?.images[0]?.image_path || 'uploads/amulet_default.png'} // ดึงภาพจาก API
                   alt={product?.item_name}
                    style={{
                        height: '100%',
                        width: 'auto',
                        objectFit: 'cover',
                    }}
                />
            </Box>
            <CardContent
                sx={{
                flexGrow: 1, // ทำให้ CardContent ขยายตัวเพื่อเติมพื้นที่ที่เหลือ
                }}
            >
                <Typography gutterBottom 
                variant="subtitle1" 
                component="div"
                sx={{
                  fontSize: '0.9rem', // กำหนดขนาดฟอนต์ที่ต้องการ
                }}
                >
                    {product.item_name} {/* ชื่อสินค้า */}
                </Typography>
                {!swiper && isLargeScreen && (
                  <Typography variant="body2" color='#a8a8a8' >
                    {product.description.length > 100 ? `${product.description.slice(0, 100)}...` : product.description} {/* คำอธิบายสินค้า */}
                  </Typography>
                )}
            </CardContent>
            <CardActions
                sx={{
                display: 'flex',
                justifyContent: 'space-between', // จัดให้ Chip อยู่ซ้าย และ Button อยู่ขวา
                alignItems: 'center', // จัดเรียงในแนวแกนกลาง
                padding: '8px', // กำหนด padding สำหรับความสวยงาม
                }}
            >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 , width: '100%'}}>
     
      <Box
  sx={{
    display: 'flex',
    justifyContent: 'space-between', // Align chips to the left and ViewCount to the right
    alignItems: 'center',
    width: '100%', // Make sure it takes full width
    borderBottom: '1px solid #4e4e4e', // Add a light border between the top and bottom boxes
    paddingBottom: 1, // Add some spacing between the box and the border
   // marginBottom: 2, // Space below the box
  }}
>
  {/* Left-aligned Chips */}
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1, // Spacing between chips
    }}
  >
    {/* State Chip */}
    <Chip
      label={getStateLabel(product.state_type)}
      variant="outlined"
      sx={{
        borderColor: getChipColor(product.state_type, theme),
        color: getChipColor(product.state_type, theme),
        fontSize: '0.6rem',
        height: '20px',
      }}
    />

    {/* Conditionally Rendered Hit Chip */}
    {product.super_hit && (
      <Chip
        label="Hit!"
        //variant="outlined"
        sx={{
          borderColor: '#ffc107',
          backgroundColor: '#ffc107',
          color: '#000',
          fontSize: '0.6rem',
          height: '20px',
        }}
      />
    )}
  </Box>
</Box>

{/* First Chip (Top) with Price */}
<Box
  sx={{
    display: 'flex',
    justifyContent: 'space-between', // Align chips to the left and ViewCount to the right
    alignItems: 'center',
    width: '100%', // Make sure it takes full width
    paddingTop: 1, // Add spacing above the box
  }}
>
  {/* Price */}
  <Typography
    variant="body2"
    sx={{
      fontSize: '1rem',
      color: 'white',
      //fontWeight: 'bold', // Optional: Make the price bold for emphasis
    }}
  >
    {Number(product.price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} ฿
  </Typography>

  {/* Right-aligned ViewCount */}
  <Box display="flex" alignItems="center">
    <RemoveRedEyeIcon sx={{ fontSize: 16, color: 'gray' }} />
    <Typography variant="body2" sx={{ ml: 0.5, color: 'gray' }}>
      {product.viewCount || 0} 
    </Typography>
  </Box>
</Box>


</Box>



                {/* <Button size="small" onClick={handleOpenDialog}  
                sx={{
                  color: theme.palette.customButton.main,
                  backgroundColor: theme.palette.customButton.background,
                  '&:hover': {
                    backgroundColor: theme.palette.customButton.hover,
                  },
                  }}>
                    รายละเอียด
                  </Button> */}
                
            </CardActions>
        </Card>

        <ProductDialog openDialog={openDialog} onClose={handleCloseDialog} product={product} />
        </>
    );
}
