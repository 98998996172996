import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


interface SearchProductProps {
  products: { item_name: string }[]; // ระบุประเภทข้อมูลของสินค้า
  onSearch: (query: string) => void; // ฟังก์ชันเพื่อส่งคำค้นไปที่ CardGrid
}

export default function SearchProduct({ products, onSearch }: SearchProductProps) {
  console.log('productsSearch',products)
  const [query, setQuery] = React.useState('');

  const options = products.map((product) => ({
    title: product.item_name,
  }));

  const handleSearch = (event: React.ChangeEvent<{}>, value: { title: string } | null) => {
    const searchQuery = value ? value.title : '';
    setQuery(searchQuery);
    onSearch(searchQuery); // ส่งคำค้นไปที่ CardGrid
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.title}
      value={options.find(option => option.title === query) || null}
      onChange={handleSearch}
      size="small"
      renderInput={(params) => <TextField {...params} label="Search Products" />}
    />
  );
}
