import React,{useEffect,useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import axios from "axios";

const PaymentTable = () => {

  const [data,setData] = useState(null)
  const [dataToday,setDataToday] = useState(null)


  useEffect(() => {
    // Function to call the API using axios
    const trackVisit = async () => {
      try {
        const res = await axios.get("api/webViewer/increment?query=all");
        setData(res.data.data.totalViews) 
        console.log('res:',res)
      } catch (error) {
        console.error("Error tracking visit:", error);
      }
    };

    const trackVisitToday = async () => {
        try {
          const res = await axios.get("api/webViewer/increment?query=today");
          setDataToday(res.data.data.userViewCount) 

        } catch (error) {
          console.error("Error tracking visit:", error);
        }
      };

    // Call the function to track the visit
    trackVisit();
    trackVisitToday();

  }, []);


  return (
    <Box
      sx={{
        maxWidth: 720,
        margin: '0 auto', // Center the table
        padding: '16px', // Add some padding around the table
      }}
    >
      <TableContainer component={Paper} sx={{ backgroundColor: '#4A4A4A' }}>
        <Table>
          <TableHead>
          <TableRow>
            <TableCell 
                colSpan={2} 
                sx={{ 
                color: 'white', 
                borderColor: '#808080', 
                textAlign: 'left', 
                fontSize: '1.5rem',
                padding: '8px 16px',
              
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <SignalCellularAltIcon />
                <Typography variant="subtitle1" sx={{ marginLeft: '0.5em' }} >
                สถิติเว็บไซต์
                </Typography>
                </Box>
              
            </TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ color: 'white', borderColor: '#808080', textAlign: 'left', padding: '8px 16px' }}>ผู้ชมทั้งหมด</TableCell>
              <TableCell sx={{ color: 'white', borderColor: '#808080', textAlign: 'right', padding: '8px 16px' }}>{data}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: 'white', borderColor: '#808080', textAlign: 'left', padding: '8px 16px' }}>ผู้ชมวันนี้</TableCell>
              <TableCell sx={{ color: 'white', borderColor: '#808080', textAlign: 'right', padding: '8px 16px' }}>{dataToday}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaymentTable;
