import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#424242', 
    },
    secondary: {
      main: '#F8E559', 
    },
    newitem: {
      main: '#6aa8eb', 
    },
    showitem: {
      main: '#1769aa',
    },
    saleitem:{
      main: '#008394',
    },
    solditem:{
      main: '#B22222',
    },
    background: {
      default: '#2e2e2e',
      navbar: '#292929',
      homepage: '#2e2e2e', 
      card: '#3d3d3d',
    },
    customButton: {
      main: '#a6a6a6', // Text color
      background: '#2e2e2e', // Background color
      hover: '#424242', // Hover color
    },

  },
  typography: {
    fontFamily: '"Kanit", "Arial", "sans-serif"',
    allVariants: {
      color: '#e6e6e6', // ตั้งค่าสีข้อความทั้งหมดเป็นสีขาว
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2e2e2e', // Use the desired color for all Paper components
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#a6a6a6', // สีขอบเริ่มต้น
          },
          '&:hover fieldset': {
            borderColor: '#424242', // สีขอบเมื่อ hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#a6a6a6', // สีขอบเมื่อ focus
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#a6a6a6', // สีของ label
        },
        focused: {
          color: '#a6a6a6', // สีของ label เมื่อ focus
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& input': {
              color: '#c2c2c2', // สีของข้อความใน multiline
            },
            '& textarea': {
              color: '#c2c2c2', // สีของข้อความใน multiline
            },
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#a6a6a6', // Text color for the Select component
          '&.Mui-focused': {
            color: '#a6a6a6', // Text color when focused
          },
          '&:hover': {
            color: '#a6a6a6', // Text color on hover
          },
        },
      },
    },
 
  },
});

export default theme;
