import React, { useEffect,useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  // '& .MuiDialogContent-root': {
  //   padding: theme.spacing(2),
  // },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    '& .MuiDialog-paper': {
      width: '90%', // ขนาดกว้างตามที่คุณต้องการ
      maxWidth: '1200px', // ขนาดสูงสุด
      margin: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      width: '90%', // ขนาดกว้างสำหรับหน้าจอเล็ก
      maxWidth: '90%', // ขนาดสูงสุดสำหรับหน้าจอเล็ก
      margin: 0,
    },
  },
}));

const CarouselContainer = styled('div')(({ theme }) => ({
  '& .carousel .thumbs-wrapper': {
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
 
  },
  '& .carousel .thumb': {
    margin: '4px', // ระยะห่างระหว่าง thumbnail

  },

  // '& .carousel .thumbs': {

  //   whiteSpace: 'wrap',
  // },


}));

const getStateLabel = (stateType) => {
  switch (stateType) {
    case 'new':
      return 'มาใหม่';
    case 'sale':
      return 'เปิดให้บูชา';
    case 'sold':
      return 'ให้บูชาแล้ว';
    case 'show':
      return 'โชว์พระ';
    default:
      return 'ไม่ทราบสถานะ';
  }
};

// ฟังก์ชันกำหนดสีของ Chip ตาม state_type
const getChipColor = (stateType, theme) => {

  switch (stateType) {
    case 'new':
      return theme.palette.newitem.main; // Use theme color
    case 'sale':
      return theme.palette.saleitem.main; // Use theme color
    case 'sold':
      return theme.palette.solditem.main; // Use theme color or customize
    case 'show':
      return theme.palette.showitem.main; // Use theme color
    default:
      return theme.palette.primary.main; // Fallback to primary color
  }
};


export default function ProductDialog({ openDialog, onClose, product }) {
  const theme = useTheme();
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const viewCount = async () => {

    const currentViewCount = parseInt(product?.viewCount, 10) || 0; 
    const updatedViewCount = currentViewCount + 1;

    const payload = {
      viewCount: updatedViewCount, 
    };
  
    const productInventoryId = product?.inventory_id; 
  
    try {

      await axios.put(`/api/inventory/${productInventoryId}/viewCount`, payload, {
        headers: {
          'Content-Type': 'application/json', 
        },
      });
  
      console.log('View count updated successfully');
    } catch (error) {
      console.error('Update failed:', error);
    }
  };

  useEffect(() => {
    if (openDialog) {
      // ล็อคการเลื่อนพื้นหลัง
      document.body.style.overflow = 'hidden';
     // document.body.style.position = 'fixed';
    } else {
      // ปลดล็อคการเลื่อนพื้นหลัง
      document.body.style.overflow = 'auto';
    //  document.body.style.position = 'relative';
    }
  
    // คืนค่าเมื่อ component ถูก unmount หรือ Dialog ปิด
    return () => {
      document.body.style.overflow = 'auto';
    //  document.body.style.position = 'relative';
    };
  }, [openDialog]);
  

  useEffect(() => {
    if (openDialog) { // เช็คว่า modal เปิดอยู่หรือไม่
      const timer = setTimeout(() => {
        viewCount();
      }, 5000);

      // Cleanup function เพื่อเคลียร์ timer ถ้า component ถูก unmount
      return () => clearTimeout(timer);
    }
  }, [openDialog, product]); 

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenImageDialog(true);
  };

  const formattedPrice = new Intl.NumberFormat('th-TH').format(product.price);
  return (

    <>
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // กระจายช่องว่าง
    padding: '5px',
  }}
>


  <IconButton
    aria-label="close"
    onClick={onClose}
    sx={{
      marginLeft: 'auto', // ผลักให้ IconButton อยู่ขวาสุด
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>

  
      <DialogContent sx={{ padding: '16px 5px' }} dividers>
      <Stack
          direction={{ sm: 'column', md: 'row' }}
          spacing={2}
          sx={{
              padding: '6px',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%', // Ensure Stack takes up full height if needed
   
          }}
      >
          <Box sx={{ flex: '60%',width:'100%',marginTop:'20px' }}>
          <CarouselContainer>
            <Carousel showThumbs={true} 
            showStatus={false} 
            infiniteLoop={true}
            swipeable={false}       
            emulateTouch={false}
            
            >
              {product.images && product.images.length > 0 ? (
                product.images.map((image, index) => (
                  <div key={index} onClick={() => handleImageClick(image.image_path)} >

                  <img
                    src={image?.image_path || 'uploads/amulet_default.png'}
                    alt={`Product ${index}`}
                    style={{
                      maxWidth: '500px',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />

                  </div>
                ))
              ) : (
                <div>
                  <img
                    src="uploads/amulet_default.png"
                    alt="Default Product"
                    style={{
                      maxWidth: '500px',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
            </Carousel>
          </CarouselContainer>
          </Box>
          <Box sx={{width: '100%'}} >
              <Typography variant='h4' gutterBottom>
                  {product.item_name}
              </Typography>
              <Chip
                label={getStateLabel(product.state_type)} // แสดงสถานะสินค้า
                 variant="outlined"
                sx={{
                    borderColor: getChipColor(product.state_type,theme), // ใช้สีของขอบจากธีม
                    color: getChipColor(product.state_type,theme), // ให้สีข้อความเข้ากับ background
                }}
                />
              <Box sx={{ borderBottom: '2px solid #ccc', my: 2 }} />
              <Typography gutterBottom>
                  {product.description}
              </Typography>
              <Typography variant='h5' gutterBottom color='orange'>
                  {formattedPrice} บาท
              </Typography>
              <Typography variant='h5' gutterBottom>
                  Contact
              </Typography>
              <Box sx={{ borderBottom: '2px solid #ccc', my: 2 }} />
              <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Chip label="Line" color="primary" />
                      <Link href="https://page.line.me/134shmyw" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography>โยธินจัดให้</Typography>
                      </Link>
                                          {/* <Typography>{product.contact_line}</Typography> */}
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Chip label="phone" color="primary" />
                      {/* <Typography>{product.contact_phone}</Typography> */}
                      <Link href={`tel:0654642859`} color="inherit" underline="none">
                      <Typography> 0654642859</Typography>
                      </Link>
                     <Link href={`tel:0989098524`} color="inherit" underline="none">
                      <Typography> 0989098524</Typography>
                      </Link>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Chip label="type" color="primary" />
                      <Typography>{product.catalog_type}</Typography>
                  </Stack>
              </Stack>
          </Box>
      </Stack>
      </DialogContent>
    </BootstrapDialog>


    <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)} fullScreen>
    <DialogTitle
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // กระจายช่องว่าง
        padding: '5px',
        paddingLeft: '20px',
      }}
    >

<Typography variant='h6' >
                  {product.item_name}
              </Typography>


      <IconButton
          aria-label="close"
          onClick={() => setOpenImageDialog(false)}
          sx={{
            marginLeft: 'auto',
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
    
      <DialogContent
  dividers
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: 0,
    overflow: 'hidden', // ซ่อนส่วนเกินของภาพ
  }}
>

      <div
        style={{
          width: '100vw', 
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={selectedImage}
          alt="Selected Product"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </div>

</DialogContent>



      </Dialog>
    </>
    
  );
}
