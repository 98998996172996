import React, { useEffect, useState } from 'react';
import {  Typography, Paper, Box } from '@mui/material';
import PCardGrid from '../components/PCardGrid';
import Poster from '../components/Poster';
import BusinessCard from '../components/BusinessCard'

const HomePage = () => {
  const [products, setProducts] = useState([]); // ใช้สถานะสำหรับผลิตภัณฑ์
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/inventory/filter?catalog_type=พระเครื่องอื่นๆ');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Poster />
      <Paper style={{ padding: 40 }}>
        <Typography variant="h1" gutterBottom>
          พระเครื่องอื่นๆ
        </Typography>
        <BusinessCard/>
        <Typography variant="subtitle1" sx={{ marginLeft: '1em', lineHeight: 1 }} gutterBottom>
        <span style={{ color: 'yellow', display: 'block', marginBottom: '8px',marginTop: '8px',fontSize:'24px' }}>
        บารมีหลวงปู่ทวด รับประกันพระแท้ เก๊คืนเงินทันที
          </span>
          </Typography>
        <Box sx={{ padding: 2, marginBottom: 4 }}>
          <PCardGrid role='user' products={products} /> 
        </Box>
      </Paper>
    </>
  );
};

export default HomePage;
